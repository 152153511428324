export const hero = {
  line1: `hi, i'm emily.`,
  line2: 'i love to build things.',
  line3: 'for the web, ios, and beyond.',
};

export const footer = {
  copyright: `${String.fromCharCode(169)} emily presser, ${(new Date()).getFullYear()}`,
  location: `${String.fromCharCode(9829)} made in the midwest`,
  message: 'psst... i run in vr too. want a preview?',
};
