export default {
  skills: ['staff frontend engineer'],
  links: ['work', 'open source', 'extras'],
  icons: [{
    id: 'mail',
    href: './resume.pdf',
  }, {
    id: 'hub',
    href: 'https://github.com/sheminusminus',
  }, {
    id: 'lin',
    href: 'https://www.linkedin.com/in/emily-kolar-0bb63a54/',
  }, {
    id: 'lab',
    href: 'https://gitlab.com/sheminusminus',
  }],
};
